import { InstitutionManagementDataProps } from "../types";
import { styled } from '@mui/material/styles';
import { Grid, IconButton } from '@mui/material';
import { GRAY_COLOR, DIPLOMA_COLOR } from "../../../../../theme";
import CustomizedTooltip from "../../../atoms/customized-tooltip";
import { Edit, Info, FilePlus, List, Slash } from 'react-feather';
import { useDispatch } from "react-redux";
import { listSigners, setDefaultSignerModalSettingsAction, setModalSettingsAction } from "../../../organisms/default-signers-modal/store/actions";
import TypeSubmit from "../../../../../domain/enum/TypeSubmit";
import { useHistory } from 'react-router-dom';
import { pageRoutes } from '../../../../utils/constants';
import { getInstitution } from "../../../organisms/institution-management-info/store/actions";
import GenerateInspectionDocumentModal from "./GenerateInspectionDocumentModal";
import { useState } from "react";
import ListInspectionFilesModal from "./ListInspectionFilesModal";
import InstitutionType from "../../../../../domain/enum/InstitutionType";

const PREFIX = 'InstitutionManagementActions';

const classes = {
  icon: `${PREFIX}-icon`,
  activeIcon: `${PREFIX}-activeIcon`,
  disabledIcon: `${PREFIX}-disabledIcon`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.icon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    color: GRAY_COLOR,
    cursor: 'not-allowed',
  },

  [`& .${classes.activeIcon}`]: {
    cursor: 'pointer',
    color: DIPLOMA_COLOR,
  },

  [`& .${classes.disabledIcon}`]: {
    cursor: 'none',
    color: GRAY_COLOR,
  },

}));

export default function InstitutionManagementActions(props: InstitutionManagementDataProps) {
  const { defaultSigner, mecCode, type } = props
  const dispatch = useDispatch();
  const history = useHistory();
  const [isGenerateInspectionDocumentModalOpen, setIsGenerateInspectionDocumentModalOpen] = useState(false);
  const [isGenerateFiscalizationFileModalOpen, setIsGenerateFiscalizationFileModalOpen] = useState(false);

  const enableInspectionDocumentIssuanceToggle = process.env.REACT_APP_ENABLE_INSPECTION_DOCUMENT_ISSUANCE === 'true';
  const enableAnnulledDiplomasIssuanceToggle = process.env.REACT_APP_ENABLE_ANNULLED_DIPLOMAS_ISSUANCE === 'true';

  const handleClickEditSigners = () => {
    dispatch(listSigners(mecCode));

    const firstIssuerSigner = defaultSigner?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 1)
    const secondIssuerSigner = defaultSigner?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 2)
    const firstRegisterSigner = defaultSigner?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 1)
    const secondRegisterSigner = defaultSigner?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 2)

    dispatch(setDefaultSignerModalSettingsAction(firstIssuerSigner, secondIssuerSigner, firstRegisterSigner, secondRegisterSigner));
    dispatch(setModalSettingsAction(true, TypeSubmit.InstitutionManagementPage, mecCode, ''));
  }

  const handleClickInfo = async () => {
    dispatch(getInstitution(mecCode));
    history.push(pageRoutes.InstitutionInfo)
  };

  const handleClickAnnulledDiplomas = async () => {
    history.push(pageRoutes.InstitutionAnnulledDiplomas)
  }

  const isIssuerInstitution = type === InstitutionType.Faculty;

  return (
    <>
      <StyledGrid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <CustomizedTooltip title="Editar Assinantes" placement="top">
            <span>
              <IconButton onClick={handleClickEditSigners} size="large">
                <Edit className={`${classes.icon} ${classes.activeIcon}`} color={DIPLOMA_COLOR} />
              </IconButton>
            </span>
          </CustomizedTooltip>
          <CustomizedTooltip title="Informações" placement="top">
            <span>
              <IconButton onClick={handleClickInfo} size="large">
                <Info className={`${classes.icon} ${classes.activeIcon}`} color={DIPLOMA_COLOR} />
              </IconButton>
            </span>
          </CustomizedTooltip>
          {enableInspectionDocumentIssuanceToggle &&
            <CustomizedTooltip title="Gerar arquivo de fiscalização" placement="top">
              <span>
                <IconButton onClick={() => { setIsGenerateInspectionDocumentModalOpen(true) }} size="large">
                  <FilePlus className={`${classes.icon} ${classes.activeIcon}`} color={DIPLOMA_COLOR} />
                </IconButton>
              </span>
            </CustomizedTooltip>
          }
          {
            enableInspectionDocumentIssuanceToggle &&
            <CustomizedTooltip title="Consultar arquivos gerados" placement="top">
              <span>
                <IconButton onClick={() => { setIsGenerateFiscalizationFileModalOpen(true) }} size="large">
                  <List className={`${classes.icon} ${classes.activeIcon}`} color={DIPLOMA_COLOR} />
                </IconButton>
              </span>
            </CustomizedTooltip>
          }
          <GenerateInspectionDocumentModal
            institutionId={mecCode}
            isOpen={isGenerateInspectionDocumentModalOpen}
            type={type}
            onClose={() => { setIsGenerateInspectionDocumentModalOpen(false) }} />
          <ListInspectionFilesModal
            institutionId={mecCode}
            isOpen={isGenerateFiscalizationFileModalOpen}
            onClose={() => { setIsGenerateFiscalizationFileModalOpen(false) }} />
          {
            enableAnnulledDiplomasIssuanceToggle &&
            <CustomizedTooltip title="Lista de diplomas anulados" placement="top">
              <span>
                <IconButton onClick={handleClickAnnulledDiplomas} size="large" disabled={isIssuerInstitution}>
                  <Slash className={`${classes.icon} ${isIssuerInstitution ? classes.disabledIcon : classes.activeIcon}`} />
                </IconButton>
              </span>
            </CustomizedTooltip>
          }
        </Grid>
      </StyledGrid>
    </>
  )
}
