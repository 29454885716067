import InsertInspectionFileRequest from '../domain/interface/request/InsertInspectionFileRequest';
import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import GetInspectionFilesRequest from '../domain/interface/request/GetInspectionFilesRequest';
import GetInspectionFilesResponse from '../domain/interface/response/GetInspectionFilesResponse';

let axiosInstance = getAxiosInstance(
  process.env.REACT_APP_INSPECTION_FILE_API || ''
);

export default class InspectionDocumentService {
  async insertInspectionFile(
    request: InsertInspectionFileRequest
  ): Promise<Promise<string>> {
    const response = await axiosInstance.post(
      'inspection-file/insert',
      request
    );

    return response?.data?.indexCode ?? 'N/A';
  }

  async getInspectionFiles(
    request: GetInspectionFilesRequest
  ): Promise<AxiosResponse<GetInspectionFilesResponse>> {
    return axiosInstance.get(`inspection-file/get/${request.mecCode}`);
  }
}
