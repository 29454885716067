import { useHistory } from "react-router-dom";
import { pageRoutes } from "../../../../utils/constants";
import { styled } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";
import DefaultTemplate from "../../../templates/default-template";
import BackCardHeader from "../../../molecules/back-card-header";
import InstitutionManagementAnnulledDiplomasTable from "../../../organisms/institution-management-annulled-diplomas";

const PREFIX = 'InstitutionAnnulledDiplomasPage';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  buttonUpload: `${PREFIX}-buttonUpload`,
  gridDropzone: `${PREFIX}-gridDropzone`,
  gridDropzoneDisabled: `${PREFIX}-gridDropzoneDisabled`,
  dropZoneBox: `${PREFIX}-dropZoneBox`,
  dropZoneBoxP: `${PREFIX}-dropZoneBoxP`,
  dropZoneBoxAdd: `${PREFIX}-dropZoneBoxAdd`,
  gridIcon: `${PREFIX}-gridIcon`,
  gridIconFile: `${PREFIX}-gridIconFile`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7),
  }
}));

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));


const InstitutionManagementAnnulledDiplomasPage = () => {

  const history = useHistory();
  const navigateBackToInstitutionManagement = () => {
    history.push(pageRoutes.InstitutionManagement)
  }

  return (
    <Root>
      <DefaultTemplate message="Lista de " highlightedMessage="Diplomas Anulados" >
        <StyledPaper>
          <BackCardHeader onClick={() => navigateBackToInstitutionManagement()} />
        </StyledPaper>
        <Grid item md={12} className={classes.root}>
          <Paper>
            <InstitutionManagementAnnulledDiplomasTable />
          </Paper>
        </Grid>
      </DefaultTemplate>
    </Root >
  )
}

export default InstitutionManagementAnnulledDiplomasPage;
