import InstitutionsSigners from '../../../../../domain/model/institution/InstitutionsSigners';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import { listInstitutionsSigners } from '../InstitutionManagementTableManager';
import {
  InstitutionManagementActions,
  SET_INSTITUTIONS_SIGNERS,
  SET_LOADING_INSTITUTIONS_SIGNERS,
  SET_PAGE_INSTITUTION,
  SET_SEARCH,
  SET_SHOW_NAME_INSTITUTION_DROPDOWN
} from './types';

const internalSetSearch = (search: string): InstitutionManagementActions => ({
  type: SET_SEARCH,
  payload: {
    search
  }
});

export const setSearch =
  (search: string): AppThunk =>
  async (dispatch) => {
    search = search.replace(/[*|[|+|(|)]/g, '');
    dispatch(internalSetSearch(search));
  };

const internalSetLoadingInstitutionsSigners = (
  loadingInstitutionsSigners: boolean
): InstitutionManagementActions => ({
  type: SET_LOADING_INSTITUTIONS_SIGNERS,
  payload: {
    loadingInstitutionsSigners
  }
});

export const setLoadingInstitutionsSigners =
  (loadingData: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingInstitutionsSigners(loadingData));
  };

const internalSetInstitutionsSigners = (
  institutionsSigners: InstitutionsSigners[]
): InstitutionManagementActions => ({
  type: SET_INSTITUTIONS_SIGNERS,
  payload: {
    institutionsSigners
  }
});

export const setInstitutionsSigners = (): AppThunk => async (dispatch) => {
  let institutionSigners: InstitutionsSigners[] = [];

  try {
    institutionSigners = await listInstitutionsSigners();
  } catch (e) {
    dispatch(
      showNotification(
        errorNotificationMessages.defaultError,
        notificationIcons.error
      )
    );
  }

  dispatch(internalSetInstitutionsSigners(institutionSigners));
  dispatch(setLoadingInstitutionsSigners(false));
};

const internalSetShowNameInstitutionDropdowAction = (
  showNameInstitutionDropdow: boolean
): InstitutionManagementActions => ({
  type: SET_SHOW_NAME_INSTITUTION_DROPDOWN,
  payload: {
    showNameInstitutionDropdow
  }
});

export const setShowNameInstitutionDropdowAction =
  (showNameInstitutionDropdow: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(
      internalSetShowNameInstitutionDropdowAction(showNameInstitutionDropdow)
    );
  };

export const setShowInternalPageInstitutionAction =
  (page: number): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetPageInstitution(page));
  };

const internalSetPageInstitution = (
  page: number
): InstitutionManagementActions => ({
  type: SET_PAGE_INSTITUTION,
  payload: {
    page
  }
});
