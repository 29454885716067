import { User } from 'oidc-client';
import { Suspense } from 'react';
import { Callback, makeAuthenticator } from 'react-oidc';
import { connect, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  withRouter
} from 'react-router-dom';
import TypePermission from './domain/enum/TypePermission';
import AuthenticationService from './services/AuthenticationService';
import store from './setup/reducer';
import { RootState } from './setup/reducer/reducers';
import HorizontalMenuBar from './shared/components/organisms/horizontal-menu-bar';
import CertificateInfoPage from './shared/components/pages/certificate-batch-info/views/CertificateInfoPage';
import EditModelCertificatePage from './shared/components/pages/certificate-edit-model-page/views/EditModelCertificatePage';
import IssuanceCertificatePage from './shared/components/pages/certificate-issuance-page/views/IssuanceCertificatePage';
import CertificatePage from './shared/components/pages/certificate-page/views/CertificatePage';
import DigitalCurriculumPage from './shared/components/pages/digital-curriculum-page/views/DigitalCurriculumPage';
import CertificateSettingsModelPage from './shared/components/pages/certificate-settings-model-page/views/CertificateSettingsModelPage';
import DiplomaManagement from './shared/components/pages/diploma-management';
import DiplomaPage from './shared/components/pages/diploma-page';
import PublicPortal from './shared/components/pages/diploma-public-portal/views/PublicPortal';
import DouPage from './shared/components/pages/dou-page';
import InstitutionManagementPage from './shared/components/pages/institution-management';
import InstitutionManagementInfoPage from './shared/components/pages/institution-management-info/views/InstitutionManagementInfoPage';
import InstitutionManagementAnnulledDiplomasPage from './shared/components/pages/institution-management-annulledDiplomas/views/InstitutionManagementAnnulledDiplomasPage';
import LoginMicrosoft from './shared/components/pages/login-microsoft';
import {
  GetUserData,
  GetUserManager
} from './shared/components/pages/login-microsoft/LoginManager';
import { updateUser } from './shared/components/pages/login-microsoft/store';
import { showNotification } from './shared/components/pages/notification/actions';
import UsersManagementPage from './shared/components/pages/users-management/views/UsersManagementPage';
import UsersSecurityPage from './shared/components/pages/users-security-page/UsersSecurityPage';
import {
  errorNotificationMessages,
  notificationIcons,
  pageRoutes
} from './shared/utils/constants';
import CurriculumValidatePage from './shared/components/pages/curriculum-validate/views/CurriculumValidatePage';
import CurriculumInfoPage from './shared/components/pages/curriculum-info/views/CurriculumInfoPage';

interface RouterProps {
  updateUserHandler?: any;
  showNotificationHandler?: any;
}

interface RenderRedirectProps {
  permissionRoles: string[]
}

export const AppWithAuth = (component: any) => {
  const userManager = useSelector(
    (state: RootState) => state.login.userManager
  );
  if (userManager) {
    return makeAuthenticator({
      userManager
    })(AwaitComponent(component));
  }
  return () => <></>;

};

function verifyPermission(permissions: string[]) {

  if (permissions.includes(TypePermission.DiplomaTrackingRead)) {
    return [pageRoutes.DiplomaPage, DiplomaPage]
  }
  if (permissions.includes(TypePermission.MicrocertificatesRead)) {
    return [pageRoutes.Certificate, CertificatePage]
  }
  if (permissions.includes(TypePermission.DigitalCurriculumRead)) {
    return [pageRoutes.DigitalCurriculum, DigitalCurriculumPage]
  }
  if (permissions.includes(TypePermission.BatchControlDouRead)) {
    return [pageRoutes.DouPage, DouPage]
  }
  if (permissions.includes(TypePermission.InstitutionManagementRead)) {
    return [pageRoutes.InstitutionManagement, InstitutionManagementPage]
  }
  if (permissions.includes(TypePermission.UsersRegistrationRead)) {
    return [pageRoutes.UsersManagement, UsersManagementPage]
  }

  return [pageRoutes.DiplomaPage, HorizontalMenuBar]

}

export function AwaitComponent(Component: any) {
  return (props: any) => (
    <Suspense fallback={<div></div>}>
      { } <Component {...props} />
    </Suspense>
  );
}

export const CallBackLogin = ({
  updateUserHandler,
  showNotificationHandler,
  history
}: any) => {
  return (
    <Callback
      onSuccess={async (user: User) => {
        let userData = GetUserData(user);
        await updateUserHandler(userData);
        if (userData?.token) {
          AuthenticationService.userPermission()
            .then(async (response) => {
              userData.roles = response.data.permissions;
              await updateUserHandler(userData);
              setTimeout(() => {
                const showSignDocuments =
                  store.getState().authorization.showSignDocuments;
                history.push(
                  showSignDocuments
                    ? pageRoutes.DiplomaManagement
                    : verifyPermission(userData.roles)[0].toString()
                );
              }, 200);
            })
            .catch(async () => {
              showNotificationHandler(
                errorNotificationMessages.failedToLogin,
                notificationIcons.error
              );
              await updateUserHandler(undefined);
              history.push(pageRoutes.LoginMicrosoft);
            });
        }
      }}
      onError={(error: any) => { }}
      userManager={GetUserManager()}
    />
  );
};
let routes: JSX.Element;

const RenderRedirect = (props: RenderRedirectProps) => {
  return (
    <Redirect
      to={verifyPermission(props.permissionRoles)[0].toString()}
    />
  )
}
interface PrivateRouteProps extends RouteProps {
  component: any;
  permission: string;
  permissionRoles: string[];
}

const PrivateRoute = ({ component: Component, permission, permissionRoles, ...rest }: Readonly<PrivateRouteProps>) => {
  const userPemission = permissionRoles.includes(permission);
  return (
    <Route
      {...rest}
      render={(props) =>
        userPemission ? (
          <Component {...props} />
        ) : <Route render={() => <RenderRedirect permissionRoles={permissionRoles} />} />
      }
    />
  );
}

export const Router = (props: RouterProps) => {
  const showSignDocuments = useSelector(
    (state: RootState) => state.authorization.showSignDocuments
  );

  const { updateUserHandler, showNotificationHandler } = props;
  const history = useHistory();
  const roles = useSelector((state: RootState) => state.login.user?.roles);
  const permissionRoles = roles ? roles.split(' ') : []

  routes = (
    <Switch>
      <Route exact path={pageRoutes.Login}>
        <CallBackLogin
          updateUserHandler={updateUserHandler}
          showNotificationHandler={showNotificationHandler}
          history={history}
        />
      </Route>
      <Route path={pageRoutes.LoginMicrosoft} component={LoginMicrosoft} />
      <Route path={pageRoutes.PublicPortal} component={PublicPortal} />

      {showSignDocuments ? (
        <PrivateRoute exact path={pageRoutes.DiplomaManagement} component={AppWithAuth(DiplomaManagement)} permissionRoles={permissionRoles} permission={TypePermission.SignDocumentsRead} />) : (<Route exact path={verifyPermission(permissionRoles)[0].toString()} component={AppWithAuth(verifyPermission(permissionRoles)[1])} />

      )}

      <PrivateRoute exact path={pageRoutes.DiplomaManagement} component={AppWithAuth(DiplomaManagement)} permissionRoles={permissionRoles} permission={TypePermission.SignDocumentsRead} />
      <PrivateRoute exact path={pageRoutes.DiplomaPage} component={AppWithAuth(DiplomaPage)} permissionRoles={permissionRoles} permission={TypePermission.DiplomaTrackingRead} />
      <PrivateRoute exact path={pageRoutes.DouPage} component={AppWithAuth(DouPage)} permissionRoles={permissionRoles} permission={TypePermission.BatchControlDouRead} />
      <PrivateRoute exact path={pageRoutes.InstitutionManagement} component={AppWithAuth(InstitutionManagementPage)} permissionRoles={permissionRoles} permission={TypePermission.InstitutionManagementRead} />
      <PrivateRoute exact path={pageRoutes.InstitutionInfo} component={AppWithAuth(InstitutionManagementInfoPage)} permissionRoles={permissionRoles} permission={TypePermission.InstitutionManagementRead} />
      <PrivateRoute exact path={pageRoutes.InstitutionAnnulledDiplomas} component={AppWithAuth(InstitutionManagementAnnulledDiplomasPage)} permissionRoles={permissionRoles} permission={TypePermission.InstitutionManagementRead} />
      <PrivateRoute exact path={pageRoutes.UsersManagement} component={AppWithAuth(UsersManagementPage)} permissionRoles={permissionRoles} permission={TypePermission.UsersRegistrationRead} />
      <PrivateRoute exact path={pageRoutes.UsersSecurity} component={AppWithAuth(UsersSecurityPage)} permissionRoles={permissionRoles} permission={TypePermission.UsersRegistrationRead} />
      <PrivateRoute exact path={pageRoutes.Certificate} component={AppWithAuth(CertificatePage)} permissionRoles={permissionRoles} permission={TypePermission.MicrocertificatesRead} />
      <PrivateRoute exact path={pageRoutes.IssuanceCertificate} component={AppWithAuth(IssuanceCertificatePage)} permissionRoles={permissionRoles} permission={TypePermission.MicrocertificatesRead} />
      <PrivateRoute exact path={pageRoutes.SettingsCertificate} component={AppWithAuth(EditModelCertificatePage)} permissionRoles={permissionRoles} permission={TypePermission.MicrocertificatesRead} />
      <PrivateRoute exact path={pageRoutes.CreateCertificateSettings} component={AppWithAuth(CertificateSettingsModelPage)} permissionRoles={permissionRoles} permission={TypePermission.MicrocertificatesRead} />
      <PrivateRoute exact path={pageRoutes.UpdateCertificateSettings} component={AppWithAuth(CertificateSettingsModelPage)} permissionRoles={permissionRoles} permission={TypePermission.MicrocertificatesRead} />
      <PrivateRoute exact path={pageRoutes.CertificateInfo} component={AppWithAuth(CertificateInfoPage)} permissionRoles={permissionRoles} permission={TypePermission.MicrocertificatesRead} />
      <PrivateRoute exact path={pageRoutes.DigitalCurriculum} component={AppWithAuth(DigitalCurriculumPage)} permissionRoles={permissionRoles} permission={TypePermission.DigitalCurriculumRead} />
      <PrivateRoute exact path={pageRoutes.DigitalCurriculumValidate} component={AppWithAuth(CurriculumValidatePage)} permissionRoles={permissionRoles} permission={TypePermission.DigitalCurriculumRead} />
      <PrivateRoute exact path={pageRoutes.DigitalCurriculumInfo} component={AppWithAuth(CurriculumInfoPage)} permissionRoles={permissionRoles} permission={TypePermission.DigitalCurriculumRead} />

      <Route
        render={() => (
          <Redirect
            to={{
              pathname: showSignDocuments ? pageRoutes.DiplomaManagement : verifyPermission(permissionRoles)[0].toString()
            }

            }
          />
        )}
      />
    </Switch>
  );

  const AppRoutes = withRouter(() => routes);
  return <> {AppRoutes && <AppRoutes />} </>;
};

const mapStateToProps = (state: RootState) => ({
  userManager: state.login.userManager
});

const mapDispatchToProps = {
  updateUserHandler: (user: any) => updateUser(user),
  showNotificationHandler: (message: string, icon: string) =>
    showNotification(message, icon)
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
