import { HeadCell, InstitutionManagementAnnulledDiplomasData } from './types';

export const headCells: HeadCell[] = [
  {
    id: 'id',
    label: 'Nº',
    size: '10%',
    align: 'left',
    sortable: true
  },
  {
    id: 'name',
    label: 'IES Registradora',
    size: '25%',
    align: 'left',
    sortable: true
  },
  {
    id: 'startApuration',
    label: 'Início da apuração',
    size: '15%',
    align: 'left',
    sortable: true
  },
  {
    id: 'endApuration',
    label: 'Fim da apuração',
    size: '15%',
    align: 'left',
    sortable: true
  },
  {
    id: 'annulledDiplomas',
    label: 'Diplomas anulados',
    size: '15%',
    align: 'left',
    sortable: true
  },
  {
    id: 'nextUpdate',
    label: 'Próxima atualização',
    size: '15%',
    align: 'left',
    sortable: true
  },
  {
    id: 'downloadXML',
    label: 'Arquivo XML',
    size: '5%',
    align: 'right',
    sortable: false
  }
];

const annulledDiplomasMock =
  (): InstitutionManagementAnnulledDiplomasData[] => {
    return [
      {
        id: 1,
        name: 'Centro Universitário AGES',
        startApuration: '01/01/2023',
        endApuration: '30/06/2023',
        annulledDiplomas: 1127,
        nextUpdate: '31/12/2023',
        downloadXML: ''
      },
      {
        id: 2,
        name: 'UNA',
        startApuration: '01/02/2023',
        endApuration: '30/07/2023',
        annulledDiplomas: 3000,
        nextUpdate: '31/01/2024',
        downloadXML: ''
      },
      {
        id: 3,
        name: 'UniBH',
        startApuration: '01/03/2023',
        endApuration: '30/08/2023',
        annulledDiplomas: 3200,
        nextUpdate: '31/02/2024',
        downloadXML: ''
      },
      {
        id: 4,
        name: 'Centro Universitário A2S',
        startApuration: '01/04/2023',
        endApuration: '30/09/2023',
        annulledDiplomas: 500,
        nextUpdate: '15/03/2024',
        downloadXML: ''
      },
      {
        id: 5,
        name: 'Centro Universitário Curitiba',
        startApuration: '01/05/2023',
        endApuration: '30/05/2023',
        annulledDiplomas: 700,
        nextUpdate: '31/04/2024',
        downloadXML: ''
      },
      {
        id: 6,
        name: 'Centro Universitário FG',
        startApuration: '20/01/2024',
        endApuration: '30/02/2024',
        annulledDiplomas: 600,
        nextUpdate: '31/02/2024',
        downloadXML: ''
      },
      {
        id: 7,
        name: 'Centro Universitário Fadergs',
        startApuration: '01/03/2023',
        endApuration: '30/08/2023',
        annulledDiplomas: 800,
        nextUpdate: '31/02/2024',
        downloadXML: ''
      },
      {
        id: 8,
        name: 'Centro Universitário IBMR',
        startApuration: '01/05/2023',
        endApuration: '30/09/2023',
        annulledDiplomas: 750,
        nextUpdate: '31/05/2024',
        downloadXML: ''
      },
      {
        id: 9,
        name: 'Centro Universitário Ritter dos Reis',
        startApuration: '01/07/2023',
        endApuration: '30/10/2023',
        annulledDiplomas: 900,
        nextUpdate: '31/08/2024',
        downloadXML: ''
      },
      {
        id: 10,
        name: 'Centro Universitário SOCIESC de Blumenau',
        startApuration: '01/03/2023',
        endApuration: '30/08/2023',
        annulledDiplomas: 1150,
        nextUpdate: '31/02/2024',
        downloadXML: ''
      },
      {
        id: 11,
        name: 'Centro Universitário São Judas Tadeu',
        startApuration: '01/03/2023',
        endApuration: '30/08/2023',
        annulledDiplomas: 1250,
        nextUpdate: '31/02/2024',
        downloadXML: ''
      }
    ];
  };

export const getRows = (): InstitutionManagementAnnulledDiplomasData[] => {
  return annulledDiplomasMock();
};
