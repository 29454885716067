import { IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { DARK_GRAY_COLOR, DIPLOMA_COLOR, GRAY_COLOR } from '../../../../theme';
import { getRows, headCells } from '../../pages/institution-management-annulledDiplomas/InstitutionManagementAnnulledDiplomasTableManager';
import EnhancedTableHead from '../enhanced-table-head';
import { InstitutionManagementAnnulledDiplomasData, RenderCellParams } from '../../pages/institution-management-annulledDiplomas/types';
import React from 'react';
import { Order } from '../enhanced-table-head/types';
import CustomizedTooltip from '../../atoms/customized-tooltip';
import { Download } from 'react-feather';
import EmptyTable from '../../molecules/empty-table';
import { orderRows } from '../../../utils/functions/table';
import axios from 'axios';

const PREFIX = 'InstitutionManagementAnnulledDiplomasTable';

const classes = {
  root: `${PREFIX}-root`,
  pagination: `${PREFIX}-pagination`,
  cellabel: `${PREFIX}-cellabel`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  [`& .${classes.pagination}`]: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },

  [`& .${classes.cellabel}`]: {
    color: DARK_GRAY_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
  },
}));


const InstitutionManagementAnnulledDiplomasTable = () => {
  const [rows, setRows] = useState<InstitutionManagementAnnulledDiplomasData[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    setRows(getRows());
  }, []);

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleDownloadButtonClick = async () => {
    const linkDownloadDocument = 'https://dev.api.diplomas.a2s.technology/document/api/document/c84e7180-bd6e-46bf-9f38-fa66f924a79d/public/download/';
    const header = { Pragma: 'no-cache' };
    const axiosInstance = axios.create({
      headers: header,
      baseURL: ''
    });
    const response = await axiosInstance.get(linkDownloadDocument);
    const linkSource = `data:application/pdf;base64,${response.data}`;
    const downloadLink = document.createElement('a');
    const fileName = `diplomasAnulados.pdf`
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const renderCell = ({
    label, labelArray = [], splitPoint = 30, cellSize = 25, textAlign = 'left',
  }: RenderCellParams) => {
    const customStyle = splitPoint >= 15 ? { width: cellSize } : { width: cellSize, paddingLeft: '40px' };

    if (labelArray.length > 0) {
      const rows: Array<JSX.Element> = [];

      labelArray.forEach((data) => {
        rows.push(
          <CustomizedTooltip key={data} title={data} placement="top">
            <Typography variant="body1">{`${(data).slice(0, 20)}...`}</Typography>
          </CustomizedTooltip>)
      });
      return (
        <TableCell align={textAlign} style={customStyle}>
          {rows}
        </TableCell>
      );
    }

    if (label) {
      if (label.length > splitPoint) {
        return (
          <TableCell align={textAlign} style={customStyle}>
            <CustomizedTooltip title={label} placement="top">
              <Typography className={classes.cellabel} variant="body1">{`${(label).slice(0, splitPoint)}...`}</Typography>
            </CustomizedTooltip>
          </TableCell>
        );
      }
      return (<TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">{label}</Typography></TableCell>);
    }

    return <TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">-</Typography></TableCell>;
  };

  const getNumPages = () => Math.ceil((rows && rows.length) / rowsPerPage) - 1;

  const buildCells = (row: InstitutionManagementAnnulledDiplomasData): React.ReactNode => (
    <TableRow
      hover
      key={row.id}
    >
      {renderCell({
        label: row.id.toString(),
        cellSize: 10,
        splitPoint: 25
      })}
      {renderCell({
        label: row.name,
        cellSize: 20,
        splitPoint: 50,
      })}
      {renderCell({
        label: row.startApuration,
        cellSize: 10,
        splitPoint: 25,
      })}
      {renderCell({
        label: row.endApuration,
        cellSize: 10,
        splitPoint: 25,
      })}
      {renderCell({
        label: row.annulledDiplomas.toString(),
        cellSize: 10,
        splitPoint: 25,
      })}
      {renderCell({
        label: row.nextUpdate,
        cellSize: 10,
        splitPoint: 25,
      })}
      <TableCell align='center'>
        <IconButton onClick={handleDownloadButtonClick}>
          <Download color={DIPLOMA_COLOR} />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  let tableContent;

  if (rows.length > 0) {
    tableContent = (
      <TableBody>
        {orderRows(order, orderBy, rows)
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => buildCells(row))}
      </TableBody>
    )
  }
  else {
    tableContent = (
      <TableBody>
        <TableRow style={{ height: 650 }}>
          <TableCell colSpan={headCells.length}>
            <EmptyTable emptyStateMessage="Nenhuma lista de Diplomas Anulados foi encontrada para esta IES!" orientationMessage="" />
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  return (
    <Root>
      <TableContainer>
        <Table>
          {rows.length > 0 &&
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              order='asc'
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
          }
          {tableContent}
        </Table>
        <TablePagination
          component="div"
          className={classes.pagination}
          count={rows.length ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={(obj: any) => (
            `${obj.from}-${obj.to} de ${obj.count}`
          )}
          nextIconButtonProps={{
            style: {
              color: page === getNumPages() ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          backIconButtonProps={{
            style: {
              color: page === 0 ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          page={page}
          onPageChange={handleChangePage}
        />
      </TableContainer>
    </Root>
  )
};

export default InstitutionManagementAnnulledDiplomasTable;
