import { AlertTriangle, Check, Clock, Download, Link2 } from 'react-feather';
import { Button, styled, Typography } from '@mui/material';
import { DARK_GRAY_COLOR, DIPLOMA_COLOR } from '../../../../theme';
import { useDispatch } from 'react-redux';
import { showSuccessNotification } from '../../pages/notification/actions';
import { notificationIcons } from '../../../utils/constants';
import Status from '../../../../domain/enum/inspectionDocument/Status';
import Root from '../../../../domain/model/inspection-file/Root';
import DocumentService from '../../../../services/DocumentService';
import axios from 'axios';
import Context from '../../../../domain/enum/inspectionDocument/Context';

const PREFIX = 'InspectionFileFeedback';

const classes = {
  feedbackContainer: `${PREFIX}-feedbackContainer`,
  feedbackIcon: `${PREFIX}-feedbackIcon`,
  feedbackText: `${PREFIX}-feedbackText`,
  feedbackSubText: `${PREFIX}-feedbackSubText`,
  subTextIcon: `${PREFIX}-subTextIcon`,
  downloadIcon: `${PREFIX}-downwloadIcon`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  disabledIcon: `${PREFIX}-disabledIcon`,
  feedBackTextContainer: `${PREFIX}-feedBackTextContainer`
};

const StyledDiv = styled('div')(({ theme }) => ({
  [`& .${classes.feedbackContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5px'
  },
  [`& .${classes.feedbackIcon}`]: {
    flex: 1,
    color: DARK_GRAY_COLOR,
  },
  [`& .${classes.feedbackText}`]: {
    flex: 11,
    fontWeight: 'bold',
    fontSize: '12px',
    color: DARK_GRAY_COLOR
  },
  [`& .${classes.feedbackSubText}`]: {
    padding: '1px 0px 0px 0px',
    flex: 11,
    color: '#737677',
    fontSize: '12px',
  },
  [`& .${classes.subTextIcon}`]: {
    color: DIPLOMA_COLOR,
    margin: '5px 0px 0px 0px'
  },
  [`& .${classes.downloadIcon}`]: {
    color: DIPLOMA_COLOR,
    padding: '0px 25px 0px 0px'
  },
  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  [`& .${classes.disabledIcon}`]: {
    color: theme.palette.grey[500]
  },
  [`& .${classes.feedBackTextContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '5px'
  },
}));

type InspectionFileFeedbackProps = {
  fiscalizationDocument: Root;
  user: string;
};


const InspectionFileFeedback = ({ fiscalizationDocument, user }: InspectionFileFeedbackProps) => {
  const dispatch = useDispatch();
  const shouldDisabledButtons = fiscalizationDocument.status !== Status.Completed;
  const generateFeedbackText = () => {
    switch (fiscalizationDocument.status) {
      case Status.Processing:
        return `${fiscalizationDocument.indexCode} - ${Status.Processing}`;
      case Status.AwaitingXmlSignature:
        return `${fiscalizationDocument.indexCode} - ${Status.AwaitingXmlSignature}`;
      case Status.GeneratingInspectionFileXml:
        return `${fiscalizationDocument.indexCode} - ${Status.GeneratingInspectionFileXml}`;
      case Status.Completed:
        return `${fiscalizationDocument.indexCode} - ${Status.Completed} em ${fiscalizationDocument.inspectionEndDate}`;
      default:
        return `${fiscalizationDocument.indexCode} - ${Status.Error}`;
    }
  }

  const convertDate = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  }

  const generateFeedbackSubtext = () => {
    const startDate = convertDate(fiscalizationDocument?.inspectionStartDate);
    const endDate = convertDate(fiscalizationDocument?.inspectionEndDate);

    if (fiscalizationDocument.status !== Status.Error)
      return `${fiscalizationDocument.context} - Período de ${startDate} a ${endDate} por ${fiscalizationDocument?.requestedBy}`;
    else if (fiscalizationDocument.status === Status.Error) {
      return `${fiscalizationDocument.context} - Período de ${startDate} a ${endDate} solicitado em ${startDate} por ${fiscalizationDocument?.requestedBy}`;
    }
  }

  const getInspectionFileId = () => {
    if (fiscalizationDocument?.pk) {
      return fiscalizationDocument?.pk.replace('InspectionFile#', '');
    }

    return undefined;
  }

  const getEmecCode = (inspectionFile: Root) => {
    return inspectionFile?.context === Context.Issuer ? inspectionFile?.issuerMecCode : inspectionFile?.registerMecCode;
  }

  const handleCopyUrlToClipboard = async () => {
    const inspectionFileDocuments = await DocumentService.listDocumentsInContext(getInspectionFileId(), 'inspectionFile');
    const inspectionFileUrlDownload = `${process.env.REACT_APP_DIPLOMA_DOCUMENT_API}document/${inspectionFileDocuments?.data[0]?.id}/public/download`

    navigator.clipboard.writeText(inspectionFileUrlDownload)
    dispatch(
      showSuccessNotification("URL copiada com sucesso.", notificationIcons.success)
    );
  }

  const getIconByStatus = () => {
    switch (fiscalizationDocument.status) {
      case Status.Processing:
      case Status.AwaitingXmlSignature:
      case Status.GeneratingInspectionFileXml:
        return <Clock size={14} className={classes.feedbackIcon} />
      case Status.Error:
        return <AlertTriangle size={14} className={classes.feedbackIcon} />
      default:
        return <Check size={14} className={classes.feedbackIcon} />
    }
  }

  const handleDownloadButtonClick = async () => {
    const inspectionFileDocuments = await DocumentService.listDocumentsInContext(getInspectionFileId(), 'inspectionFile');

    if (inspectionFileDocuments?.data?.length !== 0 && inspectionFileDocuments?.data[0]?.id) {
      const linkDownloadPublicDocument = `${process.env.REACT_APP_DIPLOMA_DOCUMENT_API}document/${inspectionFileDocuments?.data[0]?.id}/public/download`
      const header = { Pragma: 'no-cache' };
      const axiosInstance = axios.create({
        headers: header,
        baseURL: ''
      });

      const response = await axiosInstance.get(linkDownloadPublicDocument);
      const linkSource = `data:application/pdf;base64,${response.data}`;
      const downloadLink = document.createElement('a');
      const emecCode = getEmecCode(fiscalizationDocument);
      const fileName = `arquivo_de_fiscalizacao_${emecCode}_${fiscalizationDocument?.indexCode}.xml`
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  return (
    <StyledDiv>
      <div className={classes.feedbackContainer}>
        <div>
          <div className={classes.feedBackTextContainer}>
            {getIconByStatus()}
            <Typography className={classes.feedbackText}>
              {generateFeedbackText()}
            </Typography>
          </div>
          <Typography className={classes.feedbackSubText}>
            {generateFeedbackSubtext()}
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            disabled={shouldDisabledButtons}
            onClick={handleDownloadButtonClick}>
            <Download size={20}
              className={`${classes.feedbackIcon}
               ${classes.subTextIcon}
               ${shouldDisabledButtons && classes.disabledIcon}`} />
          </Button>
          <Button
            disabled={true}
            onClick={handleCopyUrlToClipboard}>
            <Link2 size={20}
              className={`${classes.feedbackIcon}
              ${classes.subTextIcon} 
              ${true && classes.disabledIcon}`} />
          </Button>
        </div>
      </div>
    </StyledDiv>
  );
};

export default InspectionFileFeedback;
