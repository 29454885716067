import { CurriculumCriteriaIntegralizationLabelsProps, TableLabelsProps } from './types';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CardTitle from '../../molecules/card-title';
import SlidersIcon from '@mui/icons-material/Tune';
import TableLoading from '../../molecules/table-loading';
import TypographyWithError from '../../molecules/typography-with-error';
import { LabelIntegralizationCriteriaDto } from '../../../../domain/interface/dto/curriculum/LabelIntegralizationCriteriaDto';

const PREFIX = 'CurriculumCriteriaIntegralizationLabelsPaper';

const classes = {
  icon: `${PREFIX}-icon`,
  table: `${PREFIX}-table`,
  tableHeaderLabel: `${PREFIX}-table-header-label`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
    transform: "rotate(90deg)"
  },
  [`& .${classes.table}`]: {
    padding: '0px 32px 24px 32px',
  },
  [`& .${classes.tableHeaderLabel}`]: {
    ...theme.typography.body1,
  }
}));

const TableRows = (labelIntegralizationCriterias: LabelIntegralizationCriteriaDto[], validation: any) => {

  const errorMessage = "Não deve ser vazio ou nulo";

  const labels = labelIntegralizationCriterias.map((label, index) => {
    return {
      ...label,
      validation: validation?.criteriaIntegralization?.labelIntegralizationCriterias &&
        Array.isArray(validation.criteriaIntegralization.labelIntegralizationCriterias) &&
        validation.criteriaIntegralization.labelIntegralizationCriterias.length > 0 ?
        validation?.criteriaIntegralization?.labelIntegralizationCriterias[index] :
        null
    }
  });

  if (Array.isArray(labels) && labels.length > 0) {
    return labels.sort((a, b) => a.code > b.code ? 1 : -1).map((label, index) => {
      return (
        <TableRow key={label?.code}>
          <TableCell component="th" scope="row">
            <TypographyWithError
              value={label?.code || 'N/A'}
              errors={label?.validation?.code} />
          </TableCell>
          <TableCell>
            <TypographyWithError
              value={label?.unitCurricular || 'N/A'}
              errors={label?.validation?.unitCurricular} />
          </TableCell>
          <TableCell align="right">
            <TypographyWithError
              value={label?.loadLimitsCriteria.minLoad ? `${label?.loadLimitsCriteria?.minLoad}h` : 'N/A'}
              errors={label?.validation?.loadLimitsCriteria?.minLoad} />
          </TableCell>
          <TableCell align="right">
            <TypographyWithError
              value={label?.loadLimitsCriteria?.maxLoad ? `${label?.loadLimitsCriteria?.maxLoad}h` : 'N/A'}
              errors={label?.validation?.loadLimitsCriteria?.maxLoad} />
          </TableCell>
          <TableCell align="right">
            <TypographyWithError
              value={label?.loadLimitsCriteria?.totalLoad ? `${label?.loadLimitsCriteria?.totalLoad}h` : 'N/A'}
              errors={label?.validation?.loadLimitsCriteria?.totalLoad} />
          </TableCell>
        </TableRow>
      )
    })
  } else {
    return (
      <TableRow>
        <TableCell component="th" scope="row">
          <TypographyWithError
            value={'N/A'}
            errors={errorMessage} />
        </TableCell>
        <TableCell>
          <TypographyWithError
            value={'N/A'}
            errors={errorMessage} />
        </TableCell>
        <TableCell align="right">
          <TypographyWithError
            value={'N/A'}
            errors={errorMessage} />
        </TableCell>
        <TableCell align="right">
          <TypographyWithError
            value={'N/A'}
            errors={errorMessage} />
        </TableCell>
        <TableCell align="right">
          <TypographyWithError
            value={'N/A'}
            errors={errorMessage} />
        </TableCell>
      </TableRow>
    )
  }
}

const TableLabels = ({ criteriaIntegralization, validation }: TableLabelsProps) => {
  const { labelIntegralizationCriterias } = criteriaIntegralization;
  return (
    <Table>
      <colgroup>
        <col style={{ width: '25%' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeaderLabel}>Código</TableCell>
          <TableCell className={classes.tableHeaderLabel}>Tipo</TableCell>
          <TableCell className={classes.tableHeaderLabel} align="right">CH Mínima</TableCell>
          <TableCell className={classes.tableHeaderLabel} align="right">CH Máxima</TableCell>
          <TableCell className={classes.tableHeaderLabel} align="right">CH Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          TableRows(labelIntegralizationCriterias, validation)
        }
      </TableBody>
    </Table>
  );
}

const TableExpressions = ({ criteriaIntegralization, validation }: TableLabelsProps) => {

  const { expressionIntegralizationCriteria } = criteriaIntegralization;
  const isEmptyArray = criteriaIntegralization.expressionIntegralizationCriteria;
  const errorMessage = "Não deve ser vazio ou nulo";
  const expressionValidation = validation?.criteriaIntegralization?.expressionIntegralizationCriteria;
  const expressionIsValid = expressionIntegralizationCriteria?.expression && !expressionValidation?.expression &&
    Array.isArray(expressionIntegralizationCriteria?.expression) &&
    expressionIntegralizationCriteria?.expression?.length > 0;

  return (
    <Table>
      <colgroup>
        <col style={{ width: '25%' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeaderLabel}>Código</TableCell>
          <TableCell className={classes.tableHeaderLabel}>Expressão</TableCell>
          <TableCell className={classes.tableHeaderLabel} align="right">CH Mínima</TableCell>
          <TableCell className={classes.tableHeaderLabel} align="right">CH Máxima</TableCell>
          <TableCell className={classes.tableHeaderLabel} align="right">CH Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={expressionIntegralizationCriteria?.code}>
          <TableCell component="th" scope="row">
            <TypographyWithError
              value={isEmptyArray ? expressionIntegralizationCriteria?.code || 'N/A' : 'N/A'}
              errors={isEmptyArray ? expressionValidation?.code : errorMessage} />
          </TableCell>
          <TableCell>
            <TypographyWithError
              value={isEmptyArray ? expressionIsValid ? expressionIntegralizationCriteria?.expression?.join(' + ') as string : 'N/A' : 'N/A'}
              errors={isEmptyArray ? expressionValidation?.expression : errorMessage}
            />
          </TableCell>
          <TableCell align="right">
            <TypographyWithError
              value={isEmptyArray ? expressionIntegralizationCriteria?.loadCriteria?.minLoad ? `${expressionIntegralizationCriteria.loadCriteria.minLoad}h` : 'N/A' : 'N/A'}
              errors={isEmptyArray ? expressionValidation?.loadCriteria?.minLoad : errorMessage} />
          </TableCell>
          <TableCell align="right">
            <TypographyWithError
              value={isEmptyArray ? expressionIntegralizationCriteria?.loadCriteria?.maxLoad ? `${expressionIntegralizationCriteria.loadCriteria.maxLoad}h` : 'N/A' : 'N/A'}
              errors={isEmptyArray ? expressionValidation?.loadCriteria?.maxLoad : errorMessage} />
          </TableCell>
          <TableCell align="right">
            <TypographyWithError
              value={isEmptyArray ? expressionIntegralizationCriteria?.loadCriteria?.totalLoad ? `${expressionIntegralizationCriteria.loadCriteria.totalLoad}h` : 'N/A' : 'N/A'}
              errors={isEmptyArray ? expressionValidation?.loadCriteria?.totalLoad : errorMessage} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

const CurriculumCriteriaIntegralizationPaper = ({ criteriaIntegralization, isLoading, validation }: CurriculumCriteriaIntegralizationLabelsProps) => {
  return (
    <StyledPaper>
      <Grid container>
        <Grid item xs={12}>
          <CardTitle icon={<SlidersIcon className={classes.icon} />}>Critérios de Integralização - Rótulos</CardTitle>
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classes.table}>
            {isLoading || !criteriaIntegralization ? <TableLoading rows={2} columns={5} /> : <TableLabels criteriaIntegralization={criteriaIntegralization} validation={validation} />}
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <CardTitle icon={<SlidersIcon className={classes.icon} />}>Critérios de Integralização - Expressão</CardTitle>
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classes.table}>
            {isLoading || !criteriaIntegralization ? <TableLoading rows={2} columns={5} /> : <TableExpressions criteriaIntegralization={criteriaIntegralization} validation={validation} />}
          </TableContainer>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default CurriculumCriteriaIntegralizationPaper;
