import React from 'react';
import BaseModal from '../../../molecules/base-modal';
import { Button, IconButton, styled, Typography } from '@mui/material';
import CustomDatePicker from '../../../atoms/form/CustomDatePicker';
import moment, { Moment } from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { DIPLOMA_COLOR } from '../../../../../theme';
import BackdropLoading from '../../../molecules/backdrop-loading';
import InspectionDocumentService from '../../../../../services/InspectionDocumentService';
import { ChevronLeft, ChevronRight } from 'react-feather';
import InspectionFileFeedback from '../../../organisms/inspection-file-feedback/InspectionFileFeedback';
import GetInspectionFilesRequest from '../../../../../domain/interface/request/GetInspectionFilesRequest';
import Root from '../../../../../domain/model/inspection-file/Root';

const PREFIX = 'ListInspectionFilesModal';

const classes = {
  contentContainer: `${PREFIX}-contentContainer`,
  closeButton: `${PREFIX}-closeButton`,
  description: `${PREFIX}-description`,
  datesContainer: `${PREFIX}-datesContainer`,
  contextLabel: `${PREFIX}-contextLabel`,
  button: `${PREFIX}-button`,
  filterButton: `${PREFIX}-filterButton`,
  pagination: `${PREFIX}-pagination`,
  filterButtonLabel: `${PREFIX}-filterButtonLabel`,
  paginationButtonsContainer: `${PREFIX}-paginationButtonsContainer`,
  disabledButton: `${PREFIX}-disabledButton`
};

const StyledDiv = styled('div')(({ theme }) => ({
  [`& .${classes.contentContainer}`]: {
    margin: '0px',
    width: '464px'
  },
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500]
  },
  [`& .${classes.description}`]: {
    marginTop: '16px',
    marginBottom: '32px'
  },
  [`& .${classes.datesContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px'
  },
  [`& .${classes.contextLabel}`]: {
    marginTop: '16px'
  },
  [`& .${classes.button}`]: {
    marginTop: '16px'
  },
  [`& .${classes.filterButton}`]: {
    color: DIPLOMA_COLOR,
    marginTop: '20px',
    fontWeight: 'bold',
    padding: '0px 0px 15px 0px'
  },
  [`& .${classes.filterButtonLabel}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px 20px 0px 0px'
  },
  [`& .${classes.paginationButtonsContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.palette.grey[500]
  },
  [`& .${classes.disabledButton}`]: {
    color: theme.palette.grey[500],
  }
}));

type ListInspectionFilesModalProps = {
  isOpen: boolean;
  institutionId: number;
  onClose: () => void;
};

const service = new InspectionDocumentService();
const pageSize = 5;

const ListInspectionFilesModal = ({ institutionId, isOpen, onClose }: ListInspectionFilesModalProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Moment | null>(null);
  const [endDate, setEndDate] = React.useState<Moment | null>(null);
  const [documents, setDocuments] = React.useState<Root[]>([]);
  const [user, setUser] = React.useState<string>('');
  const [page, setPage] = React.useState(0);

  const request: GetInspectionFilesRequest = {
    mecCode: institutionId
  }
  React.useEffect(() => {
    if (isOpen) {
      service.getInspectionFiles(request)
        .then((response) => {
          setUser(response.data.user);
          setDocuments(response.data.inspectionFiles);
        })
    }
    // Desabilitando o eslint devido o institutionId só alterar em função de uma atualização de toda aplicação
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [isOpen]);

  const clearState = () => {
    setIsLoading(false);
    setStartDate(null);
    setEndDate(null);
    setPage(0);
  }

  const clearStateAndClose = () => {
    clearState();
    setDocuments([]);
    onClose();
  }

  const onSubmit = () => {
    setIsLoading(true);
    service.getInspectionFiles(request)
      .then((response) => {
        setDocuments(response.data.inspectionFiles || []);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, 3000);
      });
  }


  return (
    <BaseModal
      onClose={clearStateAndClose}
      openModal={isOpen}
      containerContentStyle={classes.contentContainer} >
      <StyledDiv>
        <div className={classes.contentContainer}>
          <Typography variant="h3">
            Consultar Arquivos Gerados
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={clearStateAndClose}
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography className={classes.description}>
            Consulte os XMLs e as URLs dos arquivos de fiscalização gerados.
          </Typography>
          <div className={classes.datesContainer}>
            <CustomDatePicker
              value={startDate ? startDate.toString() : null}
              maxDate={endDate?.clone().subtract(1, 'days') ?? moment().subtract(1, 'days')}
              minDate={moment("2022-01-01")}
              inputLabel="Data Inicial"
              inputError={false}
              onChange={(date: Date) => setStartDate(moment(date))}
            />
            <CustomDatePicker
              value={endDate ? endDate.toString() : null}
              minDate={startDate?.clone().add(1, 'days')}
              inputLabel="Data Final"
              inputError={false}
              onChange={(date: Date) => setEndDate(moment(date))}
            />
          </div>
          <div className={classes.filterButtonLabel}>
            <Button
              variant='text'
              color='primary'
              disableElevation
              disabled={!startDate || !endDate}
              className={`${classes.filterButton}`}
              onClick={onSubmit}
            >Filtrar</Button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
              documents.slice(page, page + pageSize).map((document) => {
                return (
                  <InspectionFileFeedback fiscalizationDocument={document} key={document.pk} user={user} />
                );
              })
            }
          </div>
          <div className={classes.paginationButtonsContainer}>
            {documents.length === 0 ? 0 : page + 1}-{page + pageSize > documents.length ? documents.length : page + pageSize} de {documents.length}
            <Button
              onClick={() => setPage(page - pageSize)}
              disabled={page === 0}>
              <ChevronLeft
                className={page === 0 ? classes.disabledButton : ''} />
            </Button>
            <Button
              onClick={() => setPage(page + pageSize)}
              disabled={page + pageSize >= documents.length}>
              <ChevronRight
                className={page + pageSize >= documents.length ? classes.disabledButton : ''} />
            </Button>
          </div>
          {isLoading && (
            <BackdropLoading
              size={50}
              description='Carregando arquivos...'
              open={isLoading}
              classes={classes}
            />
          )}
        </div>
      </StyledDiv>
    </BaseModal>
  );
}

export default ListInspectionFilesModal;
